import React from 'react'
import { useEffect ,useState } from 'react';
import applications from "../jsons/Applications.json"
import CanvasShow from '../components/CanvasShow';
import FrontView from '../assets/img/calculation/FrontView.svg'
import SideView from '../assets/img/calculation/SideView.svg'
import TopView from '../assets/img/calculation/TopView.svg'
import logo from "../assets/img/logo/lumytic.webp"
import { useParams } from 'react-router-dom';
function SharedRoom() {
    const params=useParams()
    useEffect(() => {
        let data=JSON.parse(params.data);
        setCount(data[0].result.count)
        setTotalLuminairFlux(data[0].result.totalLuminairFlux)
        setSpecificLoad(data[0].result.specificLoad)
        setwidth(data[0].roomDeminsion.width);
        setheight(data[0].roomDeminsion.height);
        setlength(data[0].roomDeminsion.length);
        setRow(data[0].result.row)
        setColumn(data[0].result.column)
        chooseProduct(data[0].product,data[0].application)
    }, [])
    const nf= new Intl.NumberFormat();
    /// room
    const [width,setwidth]= useState();
    const [height,setheight] = useState();
    const [length,setlength] = useState();
    // product
    const [product, setProduct] = useState();
    const [shape, setShape] = useState();
    const [productWidth, setProductWidth] = useState();
    const [productHeight, setProductHeight] = useState();
    const [productLength, setProductLength] = useState();
    // accroding to the application ( work plane )
    const [Hd, setHd] = useState();
    /// according to the product Installation way 
    const [Ha, setHa] = useState();
    // number of products
    const [count, setCount] = useState();
    // calculate Results
    const [totalLuminairFlux,setTotalLuminairFlux]=useState();
    const [specificLoad,setSpecificLoad]=useState();
    // row and column
    const [row,setRow]=useState();
    const [column,setColumn]=useState();

    const chooseProduct=(item,application)=>{
        setProduct({
            img:item.product_photo,
            name:item.product_code,
            category:item.category,
            cct:item.CCT,
            cri:item.CRI,
            watt:item.watt,
        });
        //  Radius 
        if(item.Radius==null){
            // convert millimeter to meter
            setProductWidth(parseInt(item.Width)/1000);
            setProductLength(item.Length/1000);
        }else{
            // convert millimeter to meter
            setProductWidth(item.Radius/1000);
            setProductLength(item.Radius/1000);
        }
        // convert form millimeter to meter
        setProductHeight(item.Height/1000);
        setShape(item.shape);
        // ha = product height from ceiling
        if(item.installation_way=="Suspendant"||item.installation_way=="Pendant"){
            setHa(0.5);
        }else{
            setHa(0);
        }
        
       
        let data=  applications.filter((app)=>(
            app.application.toLowerCase() == application.toLowerCase()
        ))[0]
        setHd(data.hd);
    } 
       
  return (
    <div id='content'>
        <div className='header d-flex'>
            {/* <GoShareAndroid className='header_icon'/> */}
            <img src={logo} className='logo m-auto'/>
            {/* <FaPrint className='header_icon'/> */}
            {/* <FaCircleInfo className='header_icon'/> */}
        </div>
        <div className='content'>
            <div className='canvas_views'>
                <img src={FrontView} className="View-svg" id='default'/>
                <img src={SideView} className="View-svg" id='sideview'/>
                <img src={TopView} className="View-svg" id='topView'/>
            </div>
            <div className={`result-info`}>
                <div className='d-flex align-items-center gap-2'>
                    <h1 className='number'>
                        {count || 0}
                    </h1>
                    <span className='unit'>
                        luminairs
                    </span>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    <h1 className='number'>
                        {nf.format(totalLuminairFlux || 0) } 
                    </h1>
                    <span className='unit'>
                        lm
                    </span>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    <h1 className='number'>
                        {nf.format(specificLoad || 0)}
                    </h1>
                    <span className='unit'>
                        w/m<sup>2</sup>
                    </span>
                </div>
            </div>
            <CanvasShow
                length={length}
                width={width}
                height={height}
                product={product}
                Ha={Ha}
                Hd={Hd}
                productHeight={productHeight}
                productLength={productLength}
                productWidth={productWidth}
                shape={shape}
                row={row}
                column={column}
            />
        </div>
    </div>
  )
}

export default SharedRoom
               
