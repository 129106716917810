import React, { useRef } from 'react'
import { useEffect ,useState } from 'react';
import applications from "../jsons/Applications.json"
import axios from 'axios';
import CanvasV2 from '../components/CanvasV2';
import FrontView from '../assets/img/calculation/FrontView.svg'
import SideView from '../assets/img/calculation/SideView.svg'
import TopView from '../assets/img/calculation/TopView.svg'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getData } from '../GlobalData/pdfSlice';
import { GoShareAndroid } from "react-icons/go";
import { FaPrint } from "react-icons/fa6";
import { FaLightbulb } from "react-icons/fa6";
import { IoIosApps } from "react-icons/io";
import { RxDimensions } from "react-icons/rx";
import { BiSolidPaintRoll } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { TbReportAnalytics } from "react-icons/tb";
import { MdOutlineRateReview } from "react-icons/md";
import { FaRegStar } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import logo from "../assets/img/logo/lumytic.webp"
import Rating from 'react-rating';
import axiosInstance from '../utils/AxiosInstance';

function LightingRoomV2() {
    //// toggles
        const [productToggel, setProductToggel] = useState(false)
        const [dimensionToggel, setDimensionToggel] = useState(false)
        const [reflectionToggel, setReflectionToggel] = useState(false)
        const [applicationToggel, setApplicationToggel] = useState(false)
        const [resultToggel, setResultToggel] = useState(false)
    ////
    useEffect(() => {
        // http://192.168.1.22:12345/api/Category
        // http://localhost/lerp-system/api/Premium_product/get_all_published_products
        // http://192.168.1.22:12345/api/Premium_product/get_all_published_products
    axiosInstance.get("/getLuxProducts").then((res)=>{
        if(res.data.length>0){
            setProducts(res.data);
            setProductsBeforeFilter(res.data);
            chooseProduct(res.data[0])
            let categories=[];
            for (let i = 0; i < res.data.length; i++) {
                const category = res.data[i].category;
                // Check if category is already present in categories array
                if (!categories.includes(category)) {
                  categories.push(category);
                }
              }
            console.log(categories)
        }
    }).catch((err)=>{
        
    })
    }, [])
    const shareLink = useRef()
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const [alertMessage, setAlertMessage] = useState({show:false,content:"",title:""});
    const nf= new Intl.NumberFormat();
    /// room
    const [width, setWidth] = useState(5);
    const [height, setHeight] = useState(2);
    const [length, setLength] = useState(12);
    // product
    const [product, setProduct] = useState();
    const [shape, setShape] = useState();
    const [productWidth, setProductWidth] = useState();
    const [productHeight, setProductHeight] = useState();
    const [productLength, setProductLength] = useState();
    const [producWatt, setProducWatt] = useState();
    const [productLumens, setProductLumens] = useState();
    const [installationWay,setInstallationWay]=useState();
    // database products
    const [productsBeforeFilter, setProductsBeforeFilter] = useState();
    const [products, setProducts] = useState([]);
    /// dirty , normal .... Maintenance factory
    const [MF, setMF] = useState(0.7);
    // reflection
    const [RF_celling, setRF_celling] = useState(0.8);
    const [RF_floor, setRF_floor] = useState(0.2);
    const [RF_wall, setRF_wall] = useState(0.7);
    // lux For Appliction
    const [luxApp, setLuxApp] = useState();
    // application
    const [app, setApp] = useState();
    // get application data for the product
    const [availableApplication, setAvailableApplication] = useState([]);
    const [subApplication, setSubApplication] = useState([]);
    // accroding to the application ( work plane )
    const [Hd, setHd] = useState();
    /// according to the product Installation way 
    const [Ha, setHa] = useState();
    /// the k-index that come from the formula
    const [k, setK] = useState();
    /// UF that come from the jsons depend on the category and k-index
    const [UF, setUF] = useState();
    // distribute products in canvas 
    const [columns, setColumns] = useState();
    const [rows, setRows] = useState();
    // number of products
    const [count, setCount] = useState();
    // calculate Results
    const [totalLuminairFlux,setTotalLuminairFlux]=useState();
    const [totalPower,setTotalPower]=useState();
    const [totalAchievedIlluminance,setTotalAchievedIlluminance]=useState();
    const [specificLoad,setSpecificLoad]=useState();
    const [urlData,setUrlData]=useState();
    const [errors,setErrors]=useState({
        length:"",
        width:"",
        height:"",
    });

    const regex = /^[\.0-9]*$/;
    

    const handleChangeCeiling=(e)=>{
        if(e.target.value==0){
            setRF_wall(0)
            setRF_floor(0)
        }
        setRF_celling(e.target.value)
    }
    const handleChangeWalls=(e)=>{
        if(e.target.value==0){
            setRF_celling(0)
            setRF_floor(0)
        }
        setRF_wall(e.target.value)
    }
    const handleChangeFloor=(e)=>{
        if(e.target.value==0){
            setRF_celling(0)
            setRF_wall(0)
        }
        setRF_floor(e.target.value)
    }
    // store dimensions room
    const handleChangeWidth=(e)=>{
        if (e.target.value === "" || (regex.test(e.target.value)&&parseFloat(e.target.value)<=35)) {
            setWidth(e.target.value)
            setErrors({...errors,width:""})
        }
        if(parseFloat(e.target.value)>35){
            setErrors({...errors,width:"the width should be less than 35"})
        }
    }
    const handleChangeHieght=(e)=>{
        if (e.target.value === "" || (regex.test(e.target.value)&&parseFloat(e.target.value)<=35)) {
            setHeight(e.target.value)
            setErrors({...errors,height:""})
        }
        if(parseFloat(e.target.value)>35){
            setErrors({...errors,height:"the height should be less than 35"})
        }
    }
    const handleChangeLength=(e)=>{
        if (e.target.value === "" || (regex.test(e.target.value)&&parseFloat(e.target.value)<=35)) {
            setLength(e.target.value);
            setErrors({...errors,length:""})
        }
        if(parseFloat(e.target.value)>35){
            setErrors({...errors,length:"the length should be less than 35"})
        }
    }

    const handleChangeApp=(e)=>{
        let item=e.target.value
        let data= JSON.parse(item)
        if(!data.subApplication){
            setLuxApp(data.lux);
            setApp({application:data.application,subApp:""});
            setSubApplication([])
        }else{
            setSubApplication(data.subApplication)
            setLuxApp(data.subApplication[0].lux);
            setApp({application:data.application,subApp:data.subApplication[0].app});
        }
        setHd(data.hd);
    }
    const handleChangeSubApp=(item)=>{
        let data= JSON.parse(item)
            setLuxApp(data.lux);
            setApp({application:app.application,subApp:data.app});
    }
    // Maintenance factory
    const handleChangeMF=(e)=>{
        setMF(e.target.value);
    }
    
    const chooseProduct=(item)=>{
        setProduct({
            id: 1,
            img:item.product_photo,
            name:item.product_code,
            category:item.category,
            cct:item.CCT,
            cri:item.CRI,
            watt:item.watt,
            Height:item.Height,
            Length: item.Length,
            Lumen: item.Lumen,
            Radius: item.Radius,
            Width: item.Width,
            // application: "factory,Warehouses,Supermarkets,Other indoor public places,Parking lots,Exhibition halls ,Gas Stations",
            // installation_way: "Pendant",
            // product_code: "LUX-016614",
            // product_photo: "https://rafeed.lumytic.com/tenants/rafeed/includes/upload_files/Product/Premium/16/product_photo0.jpg",
            // shape: "Rectangular"
        });
        setProducWatt(item.watt);
        setProductLumens(item.Lumen);
        //  Radius 
        if(item.Radius==null){
            // convert millimeter to meter
            setProductWidth(parseInt(item.Width)/1000);
            setProductLength(item.Length/1000);
        }else{
            // convert millimeter to meter
            setProductWidth(item.Radius/1000);
            setProductLength(item.Radius/1000);
        }
        // convert form millimeter to meter
        setProductHeight(item.Height/1000);
        setShape(item.shape);
        setInstallationWay(item.installation_way)
        // ha = product height from ceiling
        if(item.installation_way=="Suspendant"||item.installation_way=="Pendant"){
            setHa(0.5);
        }else{
            setHa(0);
        }
        if(!applications[0].subApplication){
            setApp({application:applications[0].application,subApp:""});
            setLuxApp(applications[0].lux)
            setHd(applications[0].hd)
        }else{
            setSubApplication(applications[0].subApplication[0])
            setApp({application:applications[0].application,subApp:applications[0].subApplication[0].app});
            setLuxApp(applications[0].subApplication[0].lux)
            setHd(applications[0].hd)
        }
        setAvailableApplication(applications);
        setMF(0.7);
    } 

    const searhProduct=(e)=>{
        if(productsBeforeFilter){
            let filtered = productsBeforeFilter.filter((item)=>(
            item.product_code.toLowerCase().includes(e.target.value.toLowerCase()) || item.category.toLowerCase().includes(e.target.value.toLowerCase())
            ))
            setProducts(filtered)
        }
    }
    const print=()=>{
        if(product){
            // product Data
            let luminairData={
                model:product.name,
                img:product.img,
                length:productLength,
                height:productHeight,
                width:productWidth,
                flux:productLumens, // flux is the lumen
                power:producWatt,
            }

            let roomData={
                length:length,
                height:height,
                width:width,
                WP: Hd,// Working plan
                MF: MF,// Maintenance factor
                installation:installationWay,
                app:app, // application
            }
            let resultData={
                count: count, // number of products
                cols: columns,
                rows: rows,
                totalFlux: totalLuminairFlux, 
                totalPower:totalPower,
                specificLoad:specificLoad, // totalpower / area (width * length)
                totalAchievedIlluminance:totalAchievedIlluminance, // totallumens / area
            };
            let canvasData={
                length:length,
                width:width,
                height:height,
                product:product,
                Ha:Ha,
                Hd:Hd,
                productHeight:productHeight,
                productLength:productLength,
                productWidth:productWidth,
                shape:shape,
                count:count,
            }
            dispatch(getData({
                luminairData:luminairData,
                roomData:roomData,
                resultData:resultData,
                canvasData:canvasData
            }));
            navigate("/pdf");
        }else{
            setAlertMessage({show:true,title:"Please Choose a Product Then Print it",content:""})
        }
    }

    function generateRandomKey() {
        let data= [{
            "roomDeminsion":{
            "height":height,
            "width":width,
            "length":length
            },
            "product": product,
            "result":{
                "row":rows,
                "column":columns,
                "totalLuminairFlux":totalLuminairFlux,
                "count":count,
                "specificLoad":specificLoad
            },
            "application":app.application
        }]
        setUrlData(window.location.origin+"/"+encodeURIComponent(JSON.stringify(data)));
    }
    const copyLink=(e)=>{
        console.log(shareLink.current)
        navigator.clipboard.writeText(shareLink.current.textContent)
        e.target.textContent="Copied"
        setTimeout(() => {
            e.target.textContent="Copy"
        }, 1000);
    }
  return (
    <div id='content'>
        <div className='header row'>
            <div className='col-4'></div>
            <div className='col-4 text-center my-auto'>
                <img src={logo} className='logo'/>
            </div>
            <div className='col-4 text-end my-auto'>
                {/* <MdOutlineRateReview className='header_icon me-3' data-bs-toggle="modal" data-bs-target="#feedback"/> */}
                <GoShareAndroid className='header_icon' data-bs-toggle="modal" data-bs-target="#shareModal" onClick={generateRandomKey}/>
            </div>
            {/* <FaPrint className='header_icon'/> */}
            {/* <FaCircleInfo className='header_icon'/> */}
        </div>
        <div className='content'>
            <div className='sideBar_container'>
                <div className='sideBar_icon' data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on right" onClick={()=>setProductToggel(true)}>
                    <FaLightbulb/>
                </div>
                <div className='sideBar_icon' data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on right" onClick={()=>setDimensionToggel(true)}>
                    <RxDimensions/>
                </div>
                <div className='sideBar_icon' data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on right" onClick={()=>setApplicationToggel(true)}>
                    <IoIosApps/>
                </div>
                <div className='sideBar_icon' data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on right" onClick={()=>setReflectionToggel(true)}>
                    <BiSolidPaintRoll/>
                </div>
            </div>
            <div className='result_icon' data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on right" onClick={()=>setResultToggel(true)}>
                <TbReportAnalytics/>
            </div>
            <div className='canvas_views'>
                <img src={FrontView} className="View-svg" id='default'/>
                <img src={SideView} className="View-svg" id='sideview'/>
                <img src={TopView} className="View-svg" id='topView'/>
            </div>
            <div className={`product-info ${product?"":"d-none"}`}>
                {product&& 
                    <>
                        <span className="d-flex align-items-center">
                            <img src={product.img} className="img-info"/>
                            <div className='d-flex flex-column'>
                                <span className='ms-2 product_name'>
                                    {product.name}
                                </span>
                                <span className='ms-2 product_category'>
                                    {product.category}
                                </span>
                            </div>
                        </span>
                        <p className='description d-none d-md-block'>Areeq is a collection of light switches, Power and connectivity sockets created to take your spaces to a new dimension, presenting   the unique & innovative design. An object of desire capable of guaranteeing the quality that only Rafeed can offer you and to decorate the most exclusive environments by several wonderful Areeq colors. Feel special everyday, feel the magic in your hands with Areeq.</p>
                    </>
                }
            </div>
            <div className={`result-info`}>
                <div className='d-flex align-items-center gap-2'>
                    <h1 className='number'>
                        {count || 0}
                    </h1>
                    <span className='unit'>
                        luminairs
                    </span>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    <h1 className='number'>
                        {nf.format(totalLuminairFlux || 0) } 
                    </h1>
                    <span className='unit'>
                        lm
                    </span>
                </div>
                <div className='d-flex align-items-center gap-2'>
                    <h1 className='number'>
                        {nf.format(specificLoad || 0)}
                    </h1>
                    <span className='unit'>
                        w/m<sup>2</sup>
                    </span>
                </div>
            </div>
            <CanvasV2
                length={length}
                width={width}
                height={height}
                product={product}
                app={app}
                luxApp={luxApp}
                Ha={Ha}
                Hd={Hd}
                MF={MF}
                category={product?.category}
                RF_celling={RF_celling}
                RF_floor={RF_floor}
                RF_wall={RF_wall}
                productHeight={productHeight}
                productLength={productLength}
                productWidth={productWidth}
                productLumens={productLumens}
                producWatt={producWatt}
                shape={shape}
                setK={setK}
                setUF={setUF}
                setRows={setRows}
                setColumns={setColumns}
                setCount={setCount}
                setTotalLuminairFlux={setTotalLuminairFlux}
                setTotalPower={setTotalPower}
                setSpecificLoad={setSpecificLoad}
                setTotalAchievedIlluminance={setTotalAchievedIlluminance}
                setAlertMessage={setAlertMessage}
            />
        </div>
         {/* side Meues */}
        <div className={`${productToggel?"show":"hide"} side_menu `}>
            <IoMdClose className='closeMenu' onClick={()=>setProductToggel(false)}/>
            <div className='title'>
                <h1>Product</h1>
            </div>
            <div className='menu_body'>
                <div className='products'>
                    {products.length>0?
                        <div className="product_list">
                            {products.map((item,index)=>(
                            <div key={index} className="product" onClick={()=>chooseProduct(item)}>
                                <img src={`${item.product_photo}`} className="img-product"/>
                                <div className='d-flex flex-column align-items-center'>
                                    <span>{item.product_code}</span>
                                    <span>{item.category}</span>
                                </div>
                            </div>
                            ))}
                        </div>
                        :
                        <div className='d-flex justify-content-center'>
                            <span className='not-found'>Not Found</span>
                        </div>
                    }
                </div>
                <div className='info'>
                    <div className='sub_title'>
                            <h1>Fixture installation</h1>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>{installationWay}</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span className='w-50'>Product Distance</span>
                        <span>{Ha} m</span>
                    </div>
                </div>
                <div className='sub_title'>
                    <h1>Maintenance Factory</h1>
                    <div className='input_section d-flex justify-content-between align-items-center'>
                        <select  defaultValue="" className='input w-100'  placeholder="MF" onChange={handleChangeMF}>
                            {!MF&&  <option value={""}  hidden>MF</option>}
                                    <option value={"0.7"} selected={MF==0.7}>0.7</option>
                                    <option value={"0.8"} selected={MF==0.8}>0.8</option>
                                    <option value={"0.9"} selected={MF==0.9}>0.9</option>
                                    <option value={"0.93"}selected={MF==0.93} >0.93</option>
                        </select>    
                    </div>
                </div>
            </div>
        </div>
        {/* dimensions */}
        <div className={`${dimensionToggel?"show":"hide"} side_menu `}>
            <IoMdClose className='closeMenu' onClick={()=>setDimensionToggel(false)}/>
            <div className='title'>
                <h1>Dimensions</h1>
            </div>
            <div className='dimensions menu_body'>
                <div className='sub_title d-flex flex-column gap-2'>
                    <h1>Room</h1>
                    <div className='row'>
                        <div className='input_section col-6'>
                            <label className=''>Width (m)</label>
                            <input placeholder='Width' type='text' value={width} onChange={handleChangeWidth}/>
                            <span className="error">{errors.width}</span>
                        </div>
                        <div className='input_section col-6'>
                            <label className=''>Length (m)</label>
                            <input placeholder='Length' type='text' value={length} onChange={handleChangeLength}/>
                            <span className="error">{errors.length}</span>
                        </div>
                    </div>
                    <div className='input_section'>
                        <label className=''>Height (m)</label>
                        <input placeholder='Height' type='text'value={height} onChange={handleChangeHieght}/>
                        <span className="error">{errors.height}</span>
                    </div>
                </div>
                <div className='sub_title mt-2 d-flex flex-column gap-2'>
                    <h1>Product</h1>
                    <div className='row'>
                        <div className='input_section col-6'>
                            <label className=''>Width (m)</label>
                            <input disabled placeholder='Width' type='text' value={productWidth}/>
                        </div>
                        <div className='input_section col-6'>
                            <label className=''>Length (m)</label>
                            <input disabled placeholder='Length' type='text' value={productLength}/>
                        </div>
                    </div>
                    <div className='input_section'>
                        <label className=''>Height (m)</label>
                        <input disabled placeholder='Height' type='text' value={productHeight}/>
                    </div>
                </div>
            </div>
        </div>
        {/* application */}
        <div className={`${applicationToggel?"show":"hide"} side_menu `}>
            <IoMdClose className='closeMenu' onClick={()=>setApplicationToggel(false)}/>
            <div className='title'>
                <h1>Application</h1>
            </div>
            <div className='menu_body d-flex flex-column gap-2 pt-3'>
                <div className='input_section'>
                    <select defaultValue="" className='input' id="appSelect"  placeholder="Lux" onChange={handleChangeApp}>
                        {availableApplication.length>0&&availableApplication.map((item,index)=>(
                            <option key={index} value={JSON.stringify(item)} selected={index==0}>{item.application}</option>
                        ))}
                    </select>    
                </div>
                <div className='info'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>Work Plan</span>
                        <span>{Hd} m</span>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <span>Lux</span>
                        <span>{luxApp} lx</span>
                    </div>
                </div>
            </div>
        </div>

        {/* reflection */}
        <div className={`${reflectionToggel?"show":"hide"} side_menu `}>
            <IoMdClose className='closeMenu' onClick={()=>setReflectionToggel(false)}/>
            <div className='title'>
                <h1>Reflections</h1>
            </div>
            <div className='menu_body d-flex flex-column gap-3'>
                <div className='input_section'>
                    <label className=''>Ceil (lm)</label>
                    <select defaultValue="" className='input' id='Ceil' value={RF_celling} placeholder="Ceil" onChange={handleChangeCeiling}>
                        {!RF_celling&&  <option value={""}  hidden>RF_celling</option>}
                        <option value={"0.8"} selected={RF_celling==0.8}>0.8</option>
                        <option value={"0.7"} selected={RF_celling==0.7}>0.7</option>
                        <option value={"0.5"} selected={RF_celling==0.5}>0.5</option>
                        <option value={"0"} selected={RF_celling==0} >0</option>
                    </select>
                </div>
                <div className='input_section'>
                    <label className=''>Wall (lm)</label>
                    <select defaultValue="" className='input' value={RF_wall} placeholder="Wall" onChange={handleChangeWalls}>
                        {!RF_wall&&  <option value={""}  hidden>RF_wall</option>}
                        <option value={"0.7"} selected={RF_wall==0.7}>0.7</option>
                        <option value={"0.5"} selected={RF_wall==0.5}>0.5</option>
                        <option value={"0.3"} selected={RF_wall==0.3}>0.3</option>
                        <option value={"0"} selected={RF_wall==0} >0</option>
                    </select>
                </div>
                <div className='input_section'>
                    <label className=''>Floor (lm)</label>
                    <select defaultValue="" className='input' value={RF_floor} placeholder="Floor" onChange={handleChangeFloor}>
                        {!RF_floor&&  <option value={""}  hidden>RF_floor</option>}
                        <option value={"0.2"} selected={RF_floor==0.2}>0.2</option>
                        <option value={"0"} selected={RF_floor==0} >0</option>
                    </select>
                </div>
            </div>
        </div>

        {/* result */}
        <div className={`${resultToggel?"show":"hide"} result_popup `}>
            <IoMdClose className='closeMenu' onClick={()=>setResultToggel(false)}/>
            <div className='title'>
                <h1>Results</h1>
            </div>
            <div className='menu_body'>
                <div className=' row gy-2 gx-3'>
                    <div className='input_section col-md-6'>
                        <label>Total luminair count (pieces)</label>
                        <input disabled placeholder='Total luminair count' type='text' value={count}/>
                    </div>
                    <div className='input_section col-md-6'>
                        <label>Achieved illuminance (lm)</label>
                        <input disabled placeholder='Achieved illuminance' type='text' value={totalAchievedIlluminance}/>
                    </div>
                    <div className='input_section col-md-6'>
                        <label>Total system power (w)</label>
                        <input disabled placeholder='Total system power' type='text' value={totalPower}/>
                    </div>
                    <div className='input_section col-md-6'>
                        <label>Total luminair flux (lm)</label>
                        <input disabled placeholder='Total luminair flux' type='text' value={totalLuminairFlux}/>
                    </div>
                    <div className='input_section col col-span-2 '>
                        <div className='d-flex flex-column m-auto'>
                            <label>Specific load (w/m<sup>2</sup>)</label>
                            <input disabled placeholder='Specific load' className='w-100' type='text' value={specificLoad}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* end side Menues */}
        
        {/* share modal */}
        <div className="modal fade" id="shareModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Share Link</h1>
                        <IoMdClose className='closeMenu' data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        Anyone with this link will get <b>view access</b> to this lux calculator, and automatically start following your position in the page when they open it.
                        <div className='share-link'>
                            <p className='m-0' ref={shareLink}>{urlData}</p>
                            <div className='copy-btn-container'>
                                <button className='copy-btn' onClick={copyLink}>Copy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* end share modal */}
        {/* Feedback modal */}
        <div className="modal fade" id="feedback" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">FeedBack</h1>
                        <IoMdClose className='closeMenu' data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div className="modal-body">
                        <div className='d-flex justify-content-between align-items-center'>
                            <span>Rate us :</span>
                            <Rating
                                emptySymbol={<FaRegStar className='fs-2'/>}
                                fullSymbol={<FaStar className='text-gold fs-2'/>}
                                fractions={2}
                            />         
                        </div>              
                        <div className='input_section mt-4'>
                            <p className='mb-1'>Comment :</p>
                            <textarea rows={5} className='w-100'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* end Feedback modal */}
    </div>
  )
}

export default LightingRoomV2
               
