import React, { useEffect, useRef } from 'react'
import * as THREE from 'three';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import paperJson from "../jsons/uf/paperTable.json";
import Roboto_Regular from "../jsons/font/Roboto_Regular.json";
function CanvasV2({
    setTotalLuminairFlux,
    setOldTotalLuminairFlux,
    length,
    width,
    height,
    product,
    app,
    luxApp,
    Ha,
    Hd,
    MF,
    RF_celling,
    RF_floor,
    RF_wall,
    category,
    productHeight,
    productLength,
    productWidth,
    producWatt,
    productLumens,
    shape,
    setK, 
    setUF,  
    setRows,
    setColumns,
    setCount,
    setOldCount,
    setTotalPower,
    setSpecificLoad,
    setTotalAchievedIlluminance,
  }) 
  {
    const scene = useRef()
    var cube;
    let lumens;
    let Count;
    var renderer=null ;
    var controls;
    var camera=null;
    let loader = new FontLoader()
    let font = loader.parse(Roboto_Regular);
    let textContainerHeight=null ;
    let textContainerLength=null ;
    let textContainerWidth=null ;
    const renderAllScene=({row,column})=>{
        if(document.getElementsByTagName('canvas')[0])
        document.getElementsByTagName('canvas')[0].remove();
        scene.current = new THREE.Scene();
        scene.current.background = new THREE.Color("#333333");
      function getCamera() {
        camera = new THREE.PerspectiveCamera(10, window.innerWidth / window.innerHeight,1,5000);
        return camera;
      }
      // Axis (x,y,z)
      function addaxes(){
        const axesHelper = new THREE.AxesHelper( 5 );
        scene.current.add( axesHelper );
      }
      /*** 
      * Generate the light to be used in the scene.current. Light args:
      *   [0]: Hexadecimal color of the light
      *   [1]: Numeric value of the light's strength/intensity
      *   [2]: The distance from the light where the intensity is 0
      * @param {obj} scene.current: the current scene.current object
      **/
      function getLight(scene) {
        var light = new THREE.DirectionalLight( 0xffffff , 0.8);
        light.position.set(10,5,5 ).normalize()
        scene.add(light);
        var ambientLight = new THREE.AmbientLight(0x111111);
        scene.add(ambientLight);
        return light;
      }
      
      
      function getRenderer() {
        // Create the canvas with a renderer
        renderer = new THREE.WebGLRenderer({antialias: true});
        // Add support for retina displays
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        if(document.getElementById("canvas"))
        document.getElementById("canvas").append(renderer.domElement);
        return renderer;
      }
      function getgrid(Scene){
        const size = 1000;
        const divisions = 800;
        const colorGrid ="#272525";
        var grid = new THREE.GridHelper( size, divisions,colorGrid ,colorGrid );
        grid.position.set(0, -height-0.1, 0); // Move the grid to (2, 3, 1) in world space
        scene.current.add( grid );
      }    

      /**
      * Generate the controls to be used in the scene.current
      * @param {obj} camera: the three.js camera for the scene.current
      * @param {obj} renderer: the three.js renderer for the scene.current
      **/
      function getControls(camera, renderer) {
        controls = new OrbitControls( camera, renderer.domElement );
        controls.enableDamping = true;
        let camera_z=Math.max(width, length, height)<100?Math.max(width, length, height)*15:Math.max(width, length, height)*10;
        if(document.querySelector('#topView'))
          document.querySelector('#topView').addEventListener('click', () => {
          camera.position.x = 0;
          camera.position.y = camera_z+20;
          camera.position.z = 0;
          controls.update();
        });
        if(document.querySelector('#sideview'))
          document.querySelector('#sideview').addEventListener('click', () => {
          camera.position.x = camera_z+20;
          camera.position.y = 0;
          camera.position.z = 0;
          controls.update();
        });
        if(document.querySelector('#default'))
          document.querySelector('#default').addEventListener('click', () => {
          camera.position.x = 20;
          camera.position.y = 80;
          camera.position.z = camera_z +20;
          controls.update();
        });
        if(window.innerWidth<1100){
          camera.position.set(80, 80,200);
        }else{
          camera.position.set(80, 80,120);
        }
        
        // camera.position.set(20, 80,camera_z);
        // camera.position.set(20, 80,25 );
        controls.update();
        return controls;
      }
      function CreateRoom() {
      var cubMaterials =[
         // in the right
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.4,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
        colorWrite: true,
   
      }),
        // in the left
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.4,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
       
      }),
        // in the up
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide,
        opacity: 0.4,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
       }),
        // in the down
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 1,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
       
      }),
      /// in the front
        new THREE.MeshBasicMaterial({ 
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.4,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
       
      }),
      // in the back
        new THREE.MeshBasicMaterial({  
        color:"gray",
        side:THREE.DoubleSide,
        opacity: 0.4,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
      }),
      ];
      var geometry = new RoundedBoxGeometry( width*2,height*2, length*2);
      geometry.translate(0,0,0);// center the room
      cube = new THREE.Mesh(geometry, cubMaterials);
      cube.add(new THREE.BoxHelper(cube, new THREE.Color('#cbcbcb')));  
      scene.current.add(cube); 
      // var helper = new THREE.EdgesHelper( cube, 0x111111);


      // width line
      const materialLineWidth = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const widthPoints = [];
      widthPoints.push( new THREE.Vector3( -width,height, parseInt(length)+1.5 ) );
      widthPoints.push( new THREE.Vector3(width,height, parseInt(length)+1.5) );
      const geometryLineWidth = new THREE.BufferGeometry().setFromPoints( widthPoints );
      const widthLine = new THREE.Line( geometryLineWidth, materialLineWidth );
      scene.current.add( widthLine );

      const materialRightCornerWidth = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const widthRightCornerPoints = [];
      widthRightCornerPoints.push( new THREE.Vector3(width,height, parseInt(length)+0.5 ) );
      widthRightCornerPoints.push( new THREE.Vector3(width,height, parseInt(length)+2) );
      const geometryRightCornerWidth = new THREE.BufferGeometry().setFromPoints( widthRightCornerPoints );
      const RightCornerWidthLineLine = new THREE.Line( geometryRightCornerWidth, materialRightCornerWidth );
      scene.current.add( RightCornerWidthLineLine );

      const materialLeftCornerWidth = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const widthLeftCornerPoints = [];
      widthLeftCornerPoints.push( new THREE.Vector3(-width,height, parseInt(length)+0.5 ) );
      widthLeftCornerPoints.push( new THREE.Vector3(-width,height, parseInt(length)+2) );
      const geometryLeftCornerWidth = new THREE.BufferGeometry().setFromPoints( widthLeftCornerPoints );
      const LeftCornerWidthLineLine = new THREE.Line( geometryLeftCornerWidth, materialLeftCornerWidth );
      scene.current.add( LeftCornerWidthLineLine );


      // length line
      const materialLineLength = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const lengthPoints = [];
      lengthPoints.push( new THREE.Vector3(parseInt(width)+1.5,height, -length ) );
      lengthPoints.push( new THREE.Vector3(parseInt(width) +1.5,height, length) );
      const geometryLineLength = new THREE.BufferGeometry().setFromPoints( lengthPoints );
      const lengthLine = new THREE.Line( geometryLineLength, materialLineLength );
      scene.current.add( lengthLine );

      const materialRightCornerLength = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const lengthRightCornerPoints = [];
      lengthRightCornerPoints.push( new THREE.Vector3(parseInt(width)+0.5,height, length ) );
      lengthRightCornerPoints.push( new THREE.Vector3(parseInt(width) +2,height, length) );
      const geometryRightCornerLength = new THREE.BufferGeometry().setFromPoints( lengthRightCornerPoints );
      const RightCornerLengthLineLine = new THREE.Line( geometryRightCornerLength, materialRightCornerLength );
      scene.current.add( RightCornerLengthLineLine );

      const materialLeftCornerLength = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const lengthLeftCornerPoints = [];
      lengthLeftCornerPoints.push( new THREE.Vector3(parseInt(width)+0.5,height, -length ) );
      lengthLeftCornerPoints.push( new THREE.Vector3(parseInt(width) +2,height, -length) );
      const geometryLeftCornerLength = new THREE.BufferGeometry().setFromPoints( lengthLeftCornerPoints );
      const LeftCornerLengthLineLine = new THREE.Line( geometryLeftCornerLength, materialLeftCornerLength );
      scene.current.add( LeftCornerLengthLineLine );


      // height line
      const materialLineHeight = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const heightPoints = [];
      heightPoints.push( new THREE.Vector3(parseInt(width)+1.5,-height, parseInt(length)+1.5 ) );
      heightPoints.push( new THREE.Vector3(parseInt(width)+1.5,height, parseInt(length)+1.5) );
      const geometryLineHeight = new THREE.BufferGeometry().setFromPoints( heightPoints );
      const heightLine = new THREE.Line( geometryLineHeight, materialLineHeight );
      scene.current.add( heightLine );

      const materialRightCornerHeight = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const heightRightCornerPoints = [];
      heightRightCornerPoints.push( new THREE.Vector3(parseInt(width)+0.8,height, parseInt(length)+0.8 ) );
      heightRightCornerPoints.push( new THREE.Vector3(parseInt(width)+2,height, parseInt(length)+2) );
      const geometryRightCornerHeight = new THREE.BufferGeometry().setFromPoints( heightRightCornerPoints );
      const RightCornerHeightLineLine = new THREE.Line( geometryRightCornerHeight, materialRightCornerHeight );
      scene.current.add( RightCornerHeightLineLine );

      const materialLeftCornerHeight = new THREE.LineBasicMaterial( { color: "#E3B657",transparent: true,opacity:1 } );
      const heightLeftCornerPoints = [];
      heightLeftCornerPoints.push( new THREE.Vector3(parseInt(width)+0.8,-height, parseInt(length)+0.8 ) );
      heightLeftCornerPoints.push( new THREE.Vector3(parseInt(width)+2,-height, parseInt(length)+2) );
      const geometryLeftCornerHeight = new THREE.BufferGeometry().setFromPoints( heightLeftCornerPoints );
      const LeftCornerHeightLineLine = new THREE.Line( geometryLeftCornerHeight, materialLeftCornerHeight );
      scene.current.add( LeftCornerHeightLineLine );
   
      

      // const loader = new FontLoader();
      // loader.load( "/Roboto_Regular.json" , function ( font ) {
        // text height
        const textGeoHeight = new TextGeometry( `${height.toString()}m`, {
          font: font,
          size: 0.8,
          height: 0.2,
        } );
        let materials = [
          new THREE.MeshPhongMaterial( { color:"#E3B657", flatShading: true } ), // front
          new THREE.MeshPhongMaterial( { color:"#E3B657", flatShading: true } ) // side
        ];
        let textMeshHeight = new THREE.Mesh( textGeoHeight, materials );
        textMeshHeight.frustumCulled = false;
        textContainerHeight = new THREE.Object3D();
        textContainerHeight.position.y = 0;
        textContainerHeight.position.z =parseInt(length)+2;
        textContainerHeight.position.x = parseInt(width)+2;
        textContainerHeight.add(textMeshHeight);
        scene.current.add(textContainerHeight)

        // text width
        const textGeoWidth = new TextGeometry( `${width.toString()}m`, {
          font: font,
          size: 0.8,
          height: 0.2,
        } );
        let textMeshWidth = new THREE.Mesh( textGeoWidth, materials );
        textMeshWidth.frustumCulled = false;
        textContainerWidth = new THREE.Object3D();
        textContainerWidth.position.y = height;
        textContainerWidth.position.z =parseInt(length)+1.5;
        textContainerWidth.position.x = 0;
        textContainerWidth.add(textMeshWidth);
        scene.current.add(textContainerWidth)

        // text length
        const textGeoLength = new TextGeometry( `${length.toString()}m`, {
          font: font,
          size: 0.8,
          height: 0.2,
        } );
        let textMeshLength = new THREE.Mesh( textGeoLength, materials );
        textMeshLength.frustumCulled = false;
        textContainerLength = new THREE.Object3D();
        textContainerLength.position.y = height;
        textContainerLength.position.z =0;
        textContainerLength.position.x = parseInt(width)+1.5;
        textContainerLength.add(textMeshLength);
        scene.current.add(textContainerLength)
      // }  );

      // workPlan
      const geometry1 = new THREE.PlaneGeometry(width*2, length*2 );
      const material1 = new THREE.MeshLambertMaterial( { side: THREE.DoubleSide , color:"#cbcbcb",transparent:true,opacity:0.2 } );
      const workPlan = new THREE.Mesh( geometry1, material1 );
      workPlan.rotation.x = Math.PI / 2;
      workPlan.position.set( 0,parseFloat(-height)+(parseFloat(Hd)*2), 0 );
      scene.current.add( workPlan );
      // installationPlan (ha)
      let installationPlanMaterial= new THREE.MeshLambertMaterial({
        color:"#cbcbcb",
        side:THREE.DoubleSide ,
        opacity: 0.3,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
      });
      const geometry2 = new THREE.PlaneGeometry(width*2, length*2 );
      const installationPlan = new THREE.Mesh( geometry2, installationPlanMaterial );
      installationPlan.rotation.x = Math.PI / 2;
      installationPlan.position.set( 0,height-Ha, 0 );
      scene.current.add( installationPlan );
    }
      camera = getCamera();
      getLight(scene.current);
      getgrid(scene.current);
      renderer = getRenderer();
      // var grid = getgrid(scene.current);
      // var axes = addaxes(scene.current);
      controls = getControls(camera, renderer);
    
      // Create products 
      let materialCub = new THREE.MeshToonMaterial({
        color:"#E3B657",
        side: 2,
        transparent: false,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
        colorWrite: true,
        stencilWrite: false,
        stencilWriteMask: 255,
        stencilFunc: 519,
        stencilRef: 0,
        stencilFuncMask: 255,
        stencilFail: 7680,
        stencilZFail: 7680,
        stencilZPass: 7680
      });
      let geometryCub;
      if(shape=="Round"){
        geometryCub = new RoundedBoxGeometry( productWidth*2,productHeight*2, productLength*2,undefined,0.8);
      }
      else{
        geometryCub = new THREE.BoxGeometry( productWidth*2,productHeight*2, productLength*2);
      }
      // initialize axis
      let z;
      let x;

      if(row==2){
        z=-(length/2);
      }else if(column==2&&row==1){
        z=0;
      }else{
        // we put + productLength/2 because the drawing start here and will be overflow so we take 1 + half of product length
        z=(-length+((length/row)));
      }
      if(column==2){
        x=-(width/2);
      }else if(column==1&&row==2){
        x=0;
      }else{
        x=(-width+((width/column)));
      }
      // initialize x position
      let currentWidth=(width*2)-((width/column)*2)-((productWidth*2)*(column-1))
      let currentLength=(length*2)-((length/row)*2)-((productLength*2)*(row-1))-productLength
      for(let i=0;i<row;i++){
        for(let j=0;j<column;j++){
          if(row==1 && column==1){
              let cube2 = new THREE.Mesh(geometryCub, materialCub);
              cube2.position.setX(0);
              cube2.position.setY(height-productHeight-Ha);
              cube2.position.setZ(0);
              // if(!(cube2.position.x >= width)&&!(cube2.position.z >= length))
              scene.current.add(cube2); 
          }
            else
              if(row==1){
                if(column==2){
                  let cube2 = new THREE.Mesh(geometryCub, materialCub);
                  cube2.position.setX(x);
                  cube2.position.setY(height-productHeight-Ha);
                  cube2.position.setZ(z);
                  scene.current.add(cube2); 
                  x=width/2;
                }
                else{
                  let cube2 = new THREE.Mesh(geometryCub, materialCub);
                  cube2.position.setX(x);
                  cube2.position.setY(height-productHeight-Ha);
                  cube2.position.setZ(0);
                  scene.current.add(cube2); 
                  // if(column==2){
                  //   x=productWidth+(currentWidth/2);
                  // }else{
                    x+=(productWidth*2)+(currentWidth/(column-1));
                  // }
                  }
              }else
              if(column==2){
                let cube2 = new THREE.Mesh(geometryCub, materialCub);
                cube2.position.setX(x);
                cube2.position.setY(height-productHeight-Ha);
                cube2.position.setZ(z);
                scene.current.add(cube2); 
                x=width/2;
              }else
                if(row==2){
                  let cube2 = new THREE.Mesh(geometryCub, materialCub);
                  cube2.position.setX(x);
                  cube2.position.setY(height-productHeight-Ha);
                  cube2.position.setZ(z);
                  scene.current.add(cube2); 
                  x+=(productWidth*2)+(currentWidth/(column-1));
                }
                else
                  if(column==1){
                    let cube2 = new THREE.Mesh(geometryCub, materialCub);
                    cube2.position.setX(0);
                    cube2.position.setY(height-productHeight-Ha);
                    cube2.position.setZ(z);
                    scene.current.add(cube2); 
                  }else{    
                      let cube2 = new THREE.Mesh(geometryCub, materialCub);
                      cube2.position.setX(x);
                      cube2.position.setY(height-productHeight-Ha);
                      cube2.position.setZ(z);
                      scene.current.add(cube2); 
                      x+=(productWidth*2)+(currentWidth/(column-1));
                  }
        }      
        if(column==2){
          x=-(width/2);
        }else if(column==1&&row==2){
          x=0;
        }else{
          x=(-width+((width/column)));
        }
        if(row==2){
          z=length/2;
        }else if(column==2&&row==1){
          z=0;
        }else{
          z+=(productLength*2)+(currentLength/(row-1));
        }
      }
      CreateRoom()
      /// responsive canvas
      const loop = () => {
        renderer.render(scene.current, camera);
        window.requestAnimationFrame(loop);
        camera.updateProjectionMatrix();
        if(textContainerHeight!=null)
        textContainerHeight.lookAt(camera.position);
        if(textContainerWidth!=null)
        textContainerWidth.lookAt(camera.position);
        if(textContainerLength!=null)
        textContainerLength.lookAt(camera.position);
        controls.update();
      };
      loop();
    }
    const Calculate=(e)=>{
        let keys;
        let kArry;
        let UF=null;
        let HK =parseFloat(height)-(parseFloat(Ha)+parseFloat(Hd));
        let k=(parseFloat(width)*parseFloat(length))/((parseFloat(width) +parseFloat(length))*HK)
        k = Math.round(k * 10) / 10;
        // if(category=="Tri-Proof"){
          keys = Object.keys(paperJson.k).sort();
          kArry=paperJson.k;
        // }
        // else{
        //   keys = Object.keys(DownLightCOBJson.k).sort();
        //   kArry=DownLightCOBJson.k;
        // }
        for (let j = 0; j < keys.length; j++) {
          const key = keys[j];
          const nextKey = j+1 < keys.length ? keys[j+1] : null;
          // Nearest value
          let NearestToFirst = Math.abs(k - parseFloat(key));
          let NearestToSecond =null;
          if(nextKey!=null){
            NearestToSecond = Math.abs(k - parseFloat(nextKey));
          }
          if(key == k || NearestToSecond==null ||  NearestToFirst < NearestToSecond ){
            for(let i=0;i<kArry[key].length;i++){
              if(kArry[key][i].ceiling==RF_celling&&kArry[key][i].wall==RF_wall&&kArry[key][i].floor==RF_floor){
                UF=kArry[key][i].UF;
                break;
              }
            }
          }
          console.log(UF,"UF")
          if(UF!=null){
            break;
          }
        }
        setK(k);
        setUF(UF);
        lumens=(luxApp*width*length)/(MF*(UF/100));
        /// total lumen can room have 


        /// Count = number of products
        Count=Math.ceil(lumens/productLumens);

        // let row= Math.ceil(Count/width);
        let row= Math.ceil(Math.sqrt(Count));
        let column=Math.ceil(Count/row);
        Count=row*column;

        // if(row==1||column==1)
          if(parseInt(width)>parseInt(length)){
            let str;
            str=column;
            column=row;
            row=str;
          }
        // total system power
        let Allpower=producWatt*Count;
        setTotalPower(producWatt*Count);
        setSpecificLoad((Allpower/(width*length)).toFixed(2))
        setTotalAchievedIlluminance(((productLumens*Count)/(width*length)).toFixed(2))
        setTotalLuminairFlux(productLumens*Count)
        setCount(Count);
        setRows(row);     
        setColumns(column)
        renderAllScene({column,row});
    }
    useEffect(() => {
      if(productLumens)
        Calculate();
      else
      renderAllScene(0,0);
    }, [length,width,height,product,app,MF]
    )
    useEffect(() => {
        const resizing=()=>{
          // camera.updateProjectionMatrix();
          if(camera!=null)
          {
          camera.aspect =  window.innerWidth / window.innerHeight;
          renderer.setSize(window.innerWidth, window.innerHeight);
          }
        }
        window.addEventListener("resize",resizing);
    }, [])
  return (
      <div id="canvas"></div>
  )
}

export default CanvasV2