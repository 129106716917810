import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LightingRoomV2 from './pages/LightingRoomV2';
import PdfLighting from './pages/v1/PdfLighting';
import './assets/css/pdf.css';
import './assets/css/roomV2.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import SharedRoom from "./pages/SharedRoom";
function App() {
  return (
    <div>
       <Router>
            <Routes>
                <Route path="/" element={<LightingRoomV2 />} />
                <Route path="/:data" element={<SharedRoom />} />
                <Route path="/pdf" element={<PdfLighting />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
