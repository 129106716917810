import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = "https://lerp-controller-system-backend.atcsolution.co/website";

if(localStorage.getItem('token_website')) {
    axiosInstance.defaults.headers = {
      "Authorization": `Bearer ${localStorage.getItem('token_website')}`,
    };
}
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;