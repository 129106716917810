import React,{useEffect} from 'react'
import logo from "../../assets/img/logo/light_logo.svg"
import * as THREE from 'three';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import FrontView from '../../assets/img/calculation/FrontView.svg'
import SideView from '../../assets/img/calculation/SideView.svg'
import TopView from '../../assets/img/calculation/TopView.svg'
import Print from '../../assets/img/calculation/Print.svg'
import { useSelector } from 'react-redux';
import { selectData } from '../../GlobalData/pdfSlice';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
function PdfLighting() {
  var scene;
  var cube;
  const date= new Date().toLocaleDateString();
  const pdfDataSelect=useSelector(selectData);
  const nf= new Intl.NumberFormat();
  const renderAllScene=({row,column,canvas,camera_x,camera_y,camera_z})=>{
    scene = new THREE.Scene();
    scene.background = new THREE.Color("white");
    function getCamera() {
      camera = new THREE.PerspectiveCamera(12, window.innerWidth / window.innerHeight,1,2000);
      return camera;
    }
    /**
    * Generate the light to be used in the scene. Light args:
    *   [0]: Hexadecimal color of the light
    *   [1]: Numeric value of the light's strength/intensity
    *   [2]: The distance from the light where the intensity is 0
    * @param {obj} scene: the current scene object
    **/
    function getLight(scene) {
      var light = new THREE.DirectionalLight( 0xffffff , 0.5);
      light.position.set(10,5,5 ).normalize()
      scene.add(light);

      var ambientLight = new THREE.AmbientLight(0x111111);
      scene.add(ambientLight);
      return light;
      
    }

    function getRenderer() {
      // Create the canvas with a renderer
      var renderer = new THREE.WebGLRenderer({antialias: true});
      // Add support for retina displays
      renderer.setPixelRatio(window.devicePixelRatio);
      // Specify the size of the canvas
      renderer.setSize(document.getElementById(`right_side`).offsetWidth-5,document.getElementById(`right_side`).offsetHeight/5);
      // Add the canvas to the DOM
      // document.body.endChild(renderer.domElement);
      if(document.getElementById(`${canvas}`))
      document.getElementById(`${canvas}`).append(renderer.domElement);
      return renderer;
    }
    /**
    * Generate the controls to be used in the scene
    * @param {obj} camera: the three.js camera for the scene
    * @param {obj} renderer: the three.js renderer for the scene
    **/
    function getControls(camera, renderer) {
      controls = new OrbitControls( camera, renderer.domElement );
      controls.enableDamping = true;
      if(document.querySelector(`#topView_${canvas}`))
      document.querySelector(`#topView_${canvas}`).addEventListener('click', () => {
          camera.position.x = 0;
          camera.position.y = camera_z + 20;
          camera.position.z = 0;
          controls.update();
        });
      if(document.querySelector(`#sideview_${canvas}`))
        document.querySelector(`#sideview_${canvas}`).addEventListener('click', () => {
          camera.position.x = camera_z+20;
          camera.position.y = 0;
          camera.position.z = 0;
          controls.update();
        });
      if(document.querySelector(`#default_${canvas}`))
        document.querySelector(`#default_${canvas}`).addEventListener('click', () => {
          camera.position.x = 20;
          camera.position.y = 30;
          camera.position.z = camera_z +20;
          controls.update();
        });
      camera.position.set(camera_x,camera_y,camera_z);
      controls.update();
      return controls;
    }



    function CreateRoom() {
      var cubMaterials =[
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.1,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,}),
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.1,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
      }),
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide,
        opacity: 0.1,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,}),
        new THREE.MeshBasicMaterial({
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.1,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
      }),
        new THREE.MeshBasicMaterial({ 
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.1,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
      }),
        new THREE.MeshBasicMaterial({  
        color:"gray",
        side:THREE.DoubleSide ,
        opacity: 0.1,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
      }),
      
      ];
      var geometry = new RoundedBoxGeometry( pdfDataSelect.canvasData.width*2,pdfDataSelect.canvasData.height*2, pdfDataSelect.canvasData.length*2,);
      geometry.translate(0,0,0);
      cube = new THREE.Mesh(geometry, cubMaterials);
      cube.add(new THREE.BoxHelper(cube, new THREE.Color('#cbcbcb')));  
      scene.add(cube); 
      // work plan 
      const geometry1 = new THREE.PlaneGeometry(pdfDataSelect.canvasData.width*2, pdfDataSelect.canvasData.length*2 );
      const material1 = new THREE.MeshLambertMaterial( { side: THREE.DoubleSide , color:"#cbcbcb",transparent:true,opacity:0.2 } );
      const workPlan = new THREE.Mesh( geometry1, material1 );
      // rotation the work plane to be horizontal
      workPlan.rotation.x = Math.PI / 2;
      workPlan.position.set( 0,parseFloat(-pdfDataSelect.canvasData.height)+(parseFloat(pdfDataSelect.canvasData.Hd)*2), 0 );
      scene.add( workPlan );
      // installation height
      let installationPlanMaterial= new THREE.MeshLambertMaterial({
        color:"#cbcbcb",
        side:THREE.DoubleSide ,
        opacity: 0.3,
        transparent: true,
        depthFunc: 3,
        depthTest: true,
        depthWrite: false,
      });
      const geometry2 = new THREE.PlaneGeometry(pdfDataSelect.canvasData.width*2, pdfDataSelect.canvasData.length*2 );
      const installationPlan = new THREE.Mesh( geometry2, installationPlanMaterial );
      installationPlan.rotation.x = Math.PI / 2;
      installationPlan.position.set( 0,pdfDataSelect.canvasData.height-pdfDataSelect.canvasData.Ha, 0 );
      scene.add( installationPlan );
    }
    // Render loop
    function render() {
      requestAnimationFrame(render);
      renderer.render(scene, camera);
      controls.update();
    };
    var camera = getCamera();
    getLight(scene);
    var renderer = getRenderer();
    var controls = getControls(camera, renderer);
    let materialCub = new THREE.MeshToonMaterial({
      color:"black",
      side: 2,
      transparent: false,
      depthFunc: 3,
      depthTest: true,
      depthWrite: false,
      });
    let geometryCub;
    if(pdfDataSelect.canvasData.shape=="Round"){
      geometryCub = new RoundedBoxGeometry( pdfDataSelect.canvasData.productWidth*2,pdfDataSelect.canvasData.productHeight*2, pdfDataSelect.canvasData.productLength*2,undefined,0.8);
    }
    else{
      geometryCub = new THREE.BoxGeometry( pdfDataSelect.canvasData.productWidth*2,pdfDataSelect.canvasData.productHeight*2, pdfDataSelect.canvasData.productLength*2);
    }
     // initialize z position
     let z;
     let x;

     if(row==2){
       z=-(pdfDataSelect.canvasData.length/2);
     }else{
       z=-pdfDataSelect.canvasData.length+1;
     }
     if(column==2){
       x=-(pdfDataSelect.canvasData.width/2);
     }else{
       x=(-pdfDataSelect.canvasData.width+1);
     }
     // initialize x position
     
     let currentWidth=(pdfDataSelect.canvasData.width*2)-2-(pdfDataSelect.canvasData.productWidth*column)
     let currentLength=(pdfDataSelect.canvasData.length*2)-2-(pdfDataSelect.canvasData.productWidth*row)
     for(let i=0;i<row;i++){
       for(let j=0;j<column;j++){
         if(row==1 && column==1){
             let cube2 = new THREE.Mesh(geometryCub, materialCub);
             cube2.position.setX(0);
             cube2.position.setY(pdfDataSelect.canvasData.height-pdfDataSelect.canvasData.productHeight-pdfDataSelect.canvasData.Ha);
             cube2.position.setZ(0);
             // if(!(cube2.position.x >= width)&&!(cube2.position.z >= length))
             scene.add(cube2); 
         }
           else
             if(row==1){
               if(column==2){
                 let cube2 = new THREE.Mesh(geometryCub, materialCub);
                 cube2.position.setX(x);
                 cube2.position.setY(pdfDataSelect.canvasData.height-pdfDataSelect.canvasData.productHeight-pdfDataSelect.canvasData.Ha);
                 cube2.position.setZ(z);
                 scene.add(cube2); 
                 x=pdfDataSelect.canvasData.width/2;
               }
               else{
                 let cube2 = new THREE.Mesh(geometryCub, materialCub);
                 cube2.position.setX(x);
                 cube2.position.setY(pdfDataSelect.canvasData.height-pdfDataSelect.canvasData.productHeight-pdfDataSelect.canvasData.Ha);
                 cube2.position.setZ(0);
                 scene.add(cube2); 
                 // if(column==2){
                 //   x=productWidth+(currentWidth/2);
                 // }else{
                   x+=pdfDataSelect.canvasData.productWidth+currentWidth/(column-1);
                 // }
                 }
             }else
               if(row==2){
                 let cube2 = new THREE.Mesh(geometryCub, materialCub);
                 cube2.position.setX(x);
                 cube2.position.setY(pdfDataSelect.canvasData.height-pdfDataSelect.canvasData.productHeight-pdfDataSelect.canvasData.Ha);
                 cube2.position.setZ(z);
                 scene.add(cube2); 
                 x+=pdfDataSelect.canvasData.productWidth+currentWidth/(column-1);
               }
               else
                 if(column==1){
                   let cube2 = new THREE.Mesh(geometryCub, materialCub);
                   cube2.position.setX(0);
                   cube2.position.setY(pdfDataSelect.canvasData.height-pdfDataSelect.canvasData.productHeight-pdfDataSelect.canvasData.Ha);
                   cube2.position.setZ(z);
                   scene.add(cube2); 
                 }else{    
                     let cube2 = new THREE.Mesh(geometryCub, materialCub);
                     cube2.position.setX(x);
                     cube2.position.setY(pdfDataSelect.canvasData.height-pdfDataSelect.canvasData.productHeight-pdfDataSelect.canvasData.Ha);
                     cube2.position.setZ(z);
                     scene.add(cube2); 
                     // if(column==2){
                     //   x=width/2;
                     // }else{
                       x+=pdfDataSelect.canvasData.productWidth+currentWidth/(column-1);
                     // }
                 }
       }      
       x=(-pdfDataSelect.canvasData.width+1);
       if(row==2){
         z=pdfDataSelect.canvasData.length/2;
       }else{
         z+=pdfDataSelect.canvasData.productLength+currentLength/(row-1);
       }
     }
    
    CreateRoom()
    render();
  }
  useEffect(() =>  {
    if(Object.keys(pdfDataSelect).length !== 0){
      let row=pdfDataSelect.resultData.rows;
      let column=pdfDataSelect.resultData.cols;
      renderAllScene({column,row,canvas:'canvas_1',camera_x:0,camera_y:30,camera_z:Math.max(pdfDataSelect.canvasData.width, pdfDataSelect.canvasData.length, pdfDataSelect.canvasData.height)<100?Math.max(pdfDataSelect.canvasData.width, pdfDataSelect.canvasData.length, pdfDataSelect.canvasData.height)*8:Math.max(pdfDataSelect.canvasData.width, pdfDataSelect.canvasData.length, pdfDataSelect.canvasData.height)*5});
      renderAllScene({column,row,canvas:'canvas_2',camera_x:20,camera_y:30,camera_z:Math.max(pdfDataSelect.canvasData.width, pdfDataSelect.canvasData.length, pdfDataSelect.canvasData.height)<100?Math.max(pdfDataSelect.canvasData.width, pdfDataSelect.canvasData.length, pdfDataSelect.canvasData.height)*8:Math.max(pdfDataSelect.canvasData.width, pdfDataSelect.canvasData.length, pdfDataSelect.canvasData.height)*5});
    }
  }
  , [])


  const print = () => {
    window.print();
  };
  return (
    Object.keys(pdfDataSelect).length !== 0?(
    <div className='pdfContainer  m-auto'>
      <div className="d-flex h-100">
        {/* Left */}
        <div className='Left_side'>
          <div className='pb-3 pt-3 logo-container'>
            <img src={logo} className="logo ms-1"/>
          </div>
          <div>
            <img src={pdfDataSelect.luminairData.img} className="productImage"/>
          </div>
          <div className='d-flex justify-content-center  py-2 bg-darkblue'>
            <span className='title'>Product</span>
          </div>
          <div className='bg-white p-3 pt-1 h-fit pb-0 d-flex flex-column justify-content-between '>
            <div>
              <span className='subtitle '>Room</span>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Working PLane (WP)</span>
                <span className='info-value'>{pdfDataSelect.roomData.WP} m</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Maintenance Factor</span>
                <span className='info-value'>{pdfDataSelect.roomData.MF} m</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Fixture Installation</span>
                <span className='info-value'>{pdfDataSelect.roomData.installation}</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Application</span>
                <span className='info-value'>{pdfDataSelect.roomData.app.application}</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Sub Application</span>
                <span className='info-value'>{pdfDataSelect.roomData.app.subApp}</span>
              </div>
              <div className='d-flex justify-content-between mb-2 mt-2'>
                <span className='info'>Length</span>
                <span className='info-value'>{pdfDataSelect.roomData.length} m</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Height</span>
                <span className='info-value'>{pdfDataSelect.roomData.height} m</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Width</span>
                <span className='info-value'>{pdfDataSelect.roomData.width} m</span>
              </div>
            </div>
            <div>
              <span className='subtitle'>Result</span>
              <div className='d-flex justify-content-between mb-2 mt-2'>
                <span className='info'>Achieved illuminance (UX)</span>
                <span className='info-value'>{pdfDataSelect.resultData.totalAchievedIlluminance} lx</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Total System Power</span>
                <span className='info-value'>{nf.format(pdfDataSelect.resultData.totalPower)} watt</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Luminaires Count</span>
                <span className='info-value'>{nf.format(pdfDataSelect.resultData.count)}</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Specific Load</span>
                <span className='info-value'>{pdfDataSelect.resultData.specificLoad} w/m<sup>2</sup></span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Total Flux</span>
                <span className='info-value'>{nf.format(pdfDataSelect.resultData.totalFlux)} lx</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Columes</span>
                <span className='info-value'>{pdfDataSelect.resultData.cols}</span>
              </div>
              <div className='d-flex justify-content-between mb-2'>
                <span className='info'>Rows</span>
                <span className='info-value'>{pdfDataSelect.resultData.rows}</span>
              </div>
            </div>
          </div>
        </div>
        {/* Right */}
        <div className='right_side' id='right_side'>
          <div>
            <div className='bg-white pt-5  p-4'>
              <div className='date-container'>
                <span>Date : {date}</span>
              </div>
              <div className='print-container-pdf d-print-none' onClick={print}>
                <img src={Print}/>
              </div>
              <div>
                <span className='first-site-title'>LUX</span>
              </div>
              <div>
                <span className='sec-site-title'>CALCULATION ROOM</span>
              </div>
              <div>
                <span className='subtitle'>LUMINAIRE</span>
                <div className='d-flex justify-content-between mb-2 mt-2'>
                  <span className='info'>Model</span>
                  <span className='info-value'>{pdfDataSelect.luminairData.model}</span>
                </div>
                <div className='d-flex justify-content-between mb-2'>
                  <span className='info'>Normal Power</span>
                  <span className='info-value'>{pdfDataSelect.luminairData.power} watt</span>
                </div>
                <div className='d-flex justify-content-between mb-2'>
                  <span className='info'>Length</span>
                  <span className='info-value'>{pdfDataSelect.luminairData.length} m</span>
                </div>
                <div className='d-flex justify-content-between mb-2'>
                  <span className='info'>Height</span>
                  <span className='info-value'>{pdfDataSelect.luminairData.height} m</span>
                </div>
                <div className='d-flex justify-content-between mb-2'>
                  <span className='info'>width</span>
                  <span className='info-value'>{pdfDataSelect.luminairData.width} m</span>
                </div>
                <div className='d-flex justify-content-between mb-2'>
                  <span className='info'>Flux</span>
                  <span className='info-value'>{nf.format(pdfDataSelect.luminairData.flux)} lx</span>
                </div>
              </div>
            </div>
            <div className='ps-1'>
              <span className='subtitle text-white text-center py-2 d-block bg-darkblue'> Shot Of Calculation Room</span>
              <div id="canvas_2" className='canvas'>
                <div className='d-flex justify-content-end views d-print-none'>
                  <img src={FrontView} className="View-svg" id='default_canvas_2'/>
                  <img src={SideView} className="View-svg" id='sideview_canvas_2'/>
                  <img src={TopView} className="View-svg" id='topView_canvas_2'/>
                </div>
              </div>
            </div>
            <hr className="line"/>
            <div className='ps-1'>
              <div id="canvas_1" className='canvas'>
                <div className='d-flex justify-content-end views d-print-none'>
                  <img src={FrontView} className="View-svg" id='default_canvas_1'/>
                  <img src={SideView} className="View-svg" id='sideview_canvas_1'/>
                  <img src={TopView} className="View-svg" id='topView_canvas_1'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="note-section">
        <div className="note-container">
          <span className='subtitle ms-3 mt-2 visibility-print-hidden'>YOUR NOTES</span>
          <textarea  className="note-input"/>
        </div>
      </div>
    </div>
    ):(
    <div className='vh-100 d-flex align-items-center'>
      <div className="alert alert-warning w-50 m-auto" role="alert">
        <Link to="/" className='alert-heading text-decoration-none d-flex align-items-center mb-2'>
          <BsFillArrowLeftCircleFill className='me-2 fs-4'/> 
          <h4 className="m-0">Go Back</h4>
        </Link>
        <p>Please Go Back to the Calculating Room Page And Choose product then print it </p>
      </div>
    </div>
    )
  )
}

export default PdfLighting