import { createSlice } from '@reduxjs/toolkit'

let initialState={
    data:{}
}
const pdfSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    getData(state, action) {
      state.data=action.payload;
    },

  }
})

export const { getData } = pdfSlice.actions
export const selectData = state => state.data.data

export default pdfSlice.reducer

